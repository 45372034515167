<style scoped>
.v-text-field.v-text-field--solo .v-input__control {min-height: 40px !important;padding: 0;}
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}


</style>


<template>
<div>
  <v-flex class="px-2 py-0">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div>
                 <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
              <div>
                  <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" v-bind="attrs" hide-details label="Select Date" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu> -->
                   <v-text-field v-model="selectdate" label="Date" disabled dense hide-details outlined ></v-text-field>
                </div>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                  <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
               <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                    <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
         <v-flex xs12 class="pa-2">
              <v-container fluid >
                <v-layout row wrap align-start justify-start>
                  <v-flex  xs12 sm12 md12 lg12 xl12 class="pa-0">
                    <v-card class="a-box pa-2">
                      <div class="text-center font-weight-bold openSans mt-2">Premium Left - {{instrument}} {{expirydate}}</div>
                    <v-chart class="chart" autoresize :options="PREMIUMLEFT" theme="macarons" />
                    </v-card>
                  </v-flex>
                </v-layout>
                </v-container>
          </v-flex>
</div>
</template>
<script>
import VChart, {} from 'vue-echarts'
import '@/plugins/echart'
import { mapState } from "vuex"
export default {
  components: {
    VChart
  },
  // provide: {
  //   [THEME_KEY]: 'light'
  // },
  data () {
    return {
      selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      instrument: "BANKNIFTY",
      expirydate:'18NOV21',
    }
  },
   methods:{
      async initialFunctions() { 
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
      await this.$store.dispatch('optionChain/getOptionChain', { expiry :this.expirydate, instrument : this.instrument , page: "premiumLeft"});
      await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
    }
    
  },
  computed: {
      ...mapState('symbols',['symbolsList','expiryList','currentExpiryDate']),
      ...mapState('optionChain',['optionList','PREMIUMLEFT']),
      //  expirydate: {
      //   get:function() {return this.currentExpiryDate},
      //   set:function(val) {}
      // },
    },
  watch: {
       instrument: function(val){
        this.initialFunctions()
       },
       expirydate: function(val){
        this.initialFunctions()
       }
  },

  mounted() {
      var isDark = localStorage.getItem("dark_theme");
      var theme = (isDark == 'true')?'dark':'light';
 
    this.initialFunctions()
}
 

}
</script>










