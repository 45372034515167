<template>
  <div class="chartfullview">
    <v-container fluid grid-list-xl>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-2">
          <v-card class="a-box pa-0" width="fit-content">
          
                <v-tabs v-model="tab"  grow  centered class="horizontal-tab tabsegment c-b-white" tag="button" height="36">
                <v-tab height="28" color="#ffffff" @click="tabsChange('openinterest')">Open Interest</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabsChange('changeinoi')">Change in OI</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabsChange('premiumleft')">Premium left</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabsChange('maxpain')">Max Pain</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabsChange('putratio')">Put Call Ratio</v-tab>
                <v-tab height="28" color="#ffffff" @click="tabsChange('volumepcr')">Volume PCR</v-tab>
                </v-tabs>
           
          </v-card>
        </v-flex>
      </v-layout></v-container>
   
    <div v-if="openinterest">
      <open-interest />
    </div>
    <div v-if="changeinoi">
      <changeinopeninterest />
    </div>
    <div  v-if="maxpain">
      <maxpain />
    </div>
    <div  v-if="putratio">
      <putcallratio />
    </div>
    <div  v-if="volumepcr">
      <volumeputcallratio />
    </div>
    <div  v-if="premiumleft">
      <premiumLeft />
    </div>
  </div>
</template>
<script>
import OpenInterest from "@/views/options/oistatisticsoptions/openinterest.vue"
import Maxpain from "@/views/options/oistatisticsoptions/maxpain.vue";
import Putcallratio from "@/views/options/oistatisticsoptions/putcallratio.vue";
import Volumeputcallratio from "@/views/options/oistatisticsoptions/volumeputcallratio.vue";
import Changeinopeninterest from "@/views/options/oistatisticsoptions/changeinopeninterest.vue";
import premiumLeft from "@/views/options/oistatisticsoptions/premiumleft.vue";
export default {
  components: {
    OpenInterest,
    premiumLeft,
    Maxpain,
    Putcallratio,
    Volumeputcallratio,
    Changeinopeninterest,  
  },
  data() {
    return {
      openinterest: true,
      changeinoi: false,
      premiumleft: false,
      maxpain: false,
      putratio: false,
      volumepcr: false,
      expand: false,
      currenttab:'',
      // option: {
      //   title: {
      //     textStyle: {color: "lightgrey",fontSize: 20,},
      //     subtextStyle: {color: "lightgrey",fontSize: 18,fontWeight: 400,},
      //     left: "center",
      //     top: "center",
      //   },

      //   tooltip: {trigger: "axis",},
      //   legend: {data: ["Combined", "VWAP"],bottom: "1%",},
      //   grid: {left: "3%",right: "4%",bottom: 60,containLabel: true,},

      //   toolbox: {
      //       feature: {
      //       dataView: { show: true, readOnly: false },
      //       magicType: { show: true, type: ["line", "bar"] },
      //       restore: { show: true },
      //       saveAsImage: { show: true },
      //   },
      //   },
      //   xAxis: {
      //   type: "category",
      //   boundaryGap: false,
      //   axisPointer: {
      //   type: "shadow",shadowColor: "#E6E5E4",},
      //   data: [ "10.1","11.1","12.1","13.1","14.1","15.1","16.3","17.3","18.2","18.9","19.5","20.5","21.7","22.9",],
      //   },

      //   yAxis: {type: "value",},

      //   series: [
      //   {name: "Combined",type: "line",smooth: true,stack: "",data: [12.0, 13.2, 1.01, 13.4, 9.0, 23.0, 21.0, 45.9, 78.9, 12.0, 67.0, 23.8, 89.2, 13.8,],},
      //   {name: "VWAP",type: "line",stack: "",smooth: true,data: [12.0, 13.2, 17.01, 13.4, 19.0, 23.0, 29.0, 6.9, 81.9, 12.0, 67.0,78.8, 19.2, 17.8,],},
      //   ],
      // },
      tab: 0,
    };
  },
  methods: {
    tabReset(){this.openinterest = this.changeinoi = this.maxpain = this.putratio = this.volumepcr = this.premiumleft = false;},
    tabsChange(selectedTab) {
     this.tabReset();this[selectedTab] = true;
     selectedTab == 'openinterest' ? this.tab = 0 : selectedTab == 'changeinoi' ? this.tab = 1 : selectedTab == 'premiumleft' ? this.tab = 2 
     : selectedTab == 'maxpain' ? this.tab = 3 : selectedTab == 'putratio' ? this.tab = 4 : selectedTab == 'volumepcr' ? this.tab = 5 : this.tab = 0
     this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {});
   },
  },
  mounted(){
    this.currenttab = this.$route.query.tab;
    this.currenttab ? this.tabsChange(this.currenttab) : ''
  }
};
</script>

