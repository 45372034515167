<style scoped>
.v-btn:not(.v-btn--round).v-size--default {height: 40px;min-width: 64px;padding: 0 16px;}
</style>

<template>
  <div>
  <v-flex class="pl-2 pr-2">
    <v-container fluid>
      <v-layout row wrap align-start justify-start>
        <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
          <v-card class="a-box">
            <v-row class="ma-0" width="fit-content">
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                <div>
                  <v-select height="30" dense :items="symbolsList" v-model="instrument"  outlined  hide-details label="Instrument Name" :menu-props="{ bottom: true, offsetY: true }"></v-select>
                </div>
              </v-col>
            <v-col cols="6" lg="2" md="3" sm="4" xs="12">
              <div>
                  <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense disabled v-model="selectdate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" hide-details label="Select Date" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="selectdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectdate)">OK</v-btn>
                    </v-date-picker>
                  </v-menu> -->
                    <v-text-field v-model="selectdate" label="Date" disabled dense hide-details outlined ></v-text-field>
                </div>
              </v-col>
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
           <v-select v-model="expirydate" :items="expiryList" dense
                      outlined hide-details label="​Expiry Date" :menu-props="{ bottom: true, offsetY: true }"></v-select>
              </v-col>
              <v-col cols="6" lg="2" md="3" sm="4" xs="12">
                    <v-btn color="primary" dense>Go</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
    <v-flex xs12 class="pa-2">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0">
            <v-card class="pa-2 a-box">
              <div class="text-center font-weight-bold openSans mt-2">Max Pain - {{instrument}} {{expirydate}}</div>
              <v-chart class="chart" autoresize :options="maxpain" />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </div>
</template>

<script>
import VChart from "vue-echarts";
import "@/plugins/echart";
import { mapState } from "vuex"
export default {
  components: {
    VChart,
  },
  
  // provide: {
  //   [THEME_KEY]: "light",
  // },
  data() {
    return {
     selectdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toLocaleDateString().substr(0, 10),
      menu: false,
      instrument: "BANKNIFTY",
      expirydate: '18NOV21',
      maxpain: {
        tooltip: {trigger: "axis",
        },
        legend: {data: ["PP", "CP"],bottom: "1%",},
        grid: { left: "1%",right: "1%", bottom: 60,containLabel: true,},
        toolbox: {
          feature: {
            //dataView: { show: true, readOnly: false },
            magicType: { show: true, title: '' , type: ["line", "bar",'pie'] },
            restore: { show: false },
            saveAsImage: { show: true, title: 'Save' },
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {type: "shadow",shadowColor: "#E6E5E4",},
          data: ["10.1","11.1", "12.1", "13.4","14.7", "15.9", "16.3", "17.3","18.9","19.7",
          ],
        },
        yAxis: [
        {
            name: '',
            type: 'value',
            boundaryGap: false,
        },
        {
            name: '',
            boundaryGap: false,
            type: 'value',
        }
    ],
        series: [
          {name: "PP",type: "bar",stack: "one", smooth: true,
            data: [28.0, 94.9, 7.0, -23.2, 25.6, 76.7, 135.6, 12.2, -120.7, 113.9,],
          },
          { name: "CP", type: "bar", stack: "one", smooth: true,
            data: [80.6, 135.9, 79.0, 26.4, -28.7, 70.7, 175.6, -182.2, 140.8, -110.5,],
          },
        ],
      },
    };
  },
 methods:{
     
    async initialFunctions(){
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
    }
  },
  computed: {
      ...mapState('symbols',['symbolsList','expiryList','currentExpiryDate']),
      // expirydate: {
      //   get:function() {return this.currentExpiryDate},
      //   set:function(val) {}
      // },
    },
  watch: { 
       
  },

  mounted() {
        var isDark = localStorage.getItem("dark_theme");
      var theme = (isDark == 'true')?'dark':'light';
      // this.option.legend.textStyle = { "color": "#fff" } ;
      // this.option = Object.assign(this.global.chart.defaultTheme[theme],this.option);
    this.initialFunctions()
}
};
</script>
